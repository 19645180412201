.products {
    width     : 100%;
    margin-top: 2rem;
}

.products__wrapper {
    margin-bottom: 2rem;
}

.products_list {
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : column;
    justify-content: center;
    width          : 100%;
    margin         : 2rem 0;
}

.products__placeholder {
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    flex           : 1 0 100% !important;
    max-width      : 100% !important;
    padding        : 5rem 1rem;
    color          : rgba(0, 0, 0, 0.5) !important;
}

@media screen and (min-width: 576px) {}

@media screen and (min-width: 768px) {
    .products_list {
        flex-direction : row;
        justify-content: center;
    }

    .products_list>div {

        flex     : 1 0 31%;
        max-width: 31%;
        margin   : 0 1rem 2rem 0;
    }

    .products_list>div:nth-child(3n) {
        margin-right: 0;
    }
}

@media screen and (min-width: 992px) {}

@media screen and (min-width: 1200px) {
    .products_list>div {
        flex     : 1 0 22%;
        max-width: 22%;
    }

    .products_list>div:nth-child(3n) {
        margin-right: 1rem;
    }

    .products_list>div:nth-child(4n) {
        margin-right: 0;
    }
}