.formNewsletter .form-control {
    padding: 0.75rem;
}

.formNewsletter .input-group {
    width: 80% !important;
}

.formNewsletter .button__newsletter {
    color                     : #FFFFFF !important;
    border                    : none !important;
    border-top-right-radius   : 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
    background-color          : #F5AE30 !important;
}

.formNewsletter .button__newsletter:focus {
    border-color: #F5AE30 !important;
    box-shadow  : 0 0 0 0.25rem rgb(245 174 48 / 25%) !important;
}