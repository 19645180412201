.news_card {
    position      : relative;
    display       : flex;
    flex-wrap     : wrap;
    flex-direction: column;
    background    : #FBFBFB;
}

.news_card__link {
    position  : absolute;
    top       : 0;
    left      : 0;
    z-index   : 9999;
    width     : 100%;
    min-height: 100%;
}

.news_card__image {
    width              : 100%;
    height             : 300px;
    background-repeat  : no-repeat;
    background-size    : 80%;
    background-position: center;
    background-color   : #F5F5F5;
}

.news_card__body {
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : column;
    justify-content: flex-start;
    align-items    : flex-start;
    width          : 100%;
    padding        : 1rem;
}

.news_card__date {
    width         : 100%;
    margin-bottom : 0.75rem;
    color         : #4E5255;
    font-size     : 0.75rem;
    word-wrap     : wrap;
    text-transform: uppercase;
}

.news_card__title {
    width         : 100%;
    margin-bottom : 1rem;
    color         : #F5AE30;
    font-size     : 0.9rem;
    font-weight   : bold;
    word-wrap     : wrap;
    text-transform: uppercase;
}

@media screen and (min-width: 576px) {}

@media screen and (min-width: 768px) {}

@media screen and (min-width: 992px) {
    .news_card__image {
        max-height: 280px;
    }
}

@media screen and (min-width: 1200px) {}

.news_card:hover {
    transform: scale(1.05);
}