.main_content {
    margin-bottom: 3rem;
    padding      : 0 1rem;
}

@media screen and (min-width: 576px) {}

@media screen and (min-width: 768px) {
    .main_content {
        padding: 0 2rem;
    }
}

@media screen and (min-width: 992px) {}

@media screen and (min-width: 1200px) {
    .main_content {
        padding: 0 4rem;
    }
}