.featured {
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : column;
    justify-content: center;
    width          : 100%;
    margin         : 2rem 0;
    padding        : 0 1rem;
}

.featured__partner {
    width              : 150px;
    height             : 150px;
    margin-bottom      : 2rem;
    background-repeat  : no-repeat;
    background-size    : contain;
    background-position: center;
}

.featured__title {
    width         : 100%;
    margin-bottom : 1rem;
    color         : #F5AE30;
    font-size     : 1.25rem;
    font-weight   : bold;
    text-transform: uppercase;
    text-align    : center;
}

.featured__body {
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
}

.featured__wrapper {
    display        : flex;
    flex-wrap      : nowrap;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    width          : 100%;
    margin-top     : 1rem;
}

.featured__button {
    padding-top     : 1rem !important;
    padding-bottom  : 1rem !important;
    font-size       : 0.9rem !important;
    text-transform  : uppercase;
    border-color    : #3896B3 !important;
    background-color: #3896B3 !important;
}

@media screen and (min-width: 576px) {
    .featured__body {
        flex-wrap      : wrap;
        flex-direction : row;
        justify-content: center;
    }

    .featured__partner {
        margin: 1rem;
    }
}

@media screen and (min-width: 768px) {
    .featured__title {
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 992px) {}

@media screen and (min-width: 1200px) {
    .featured__title {
        font-size: 1.75rem;
    }

}

.featured__partner:hover {
    cursor   : pointer;
    transform: scale(1.1);
}