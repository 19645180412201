.subscriptionOverlay {
    position       : fixed;
    top            : 0;
    left           : 0;
    z-index        : 999;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    width          : 100%;
    height         : 100vh;
    background     : rgba(0, 0, 0, 0.5);

    .message {
        position  : relative;
        padding   : 4rem;
        color     : #202020;
        text-align: center;
        background: #FFFFFF;
    }

    .header {
        display       : flex;
        flex-direction: column;
        align-items   : center;
        margin-bottom : 1.5rem;
    }

    svg {
        width        : 8rem;
        height       : 8rem;
        margin-bottom: 1.75rem;
        fill         : green;
    }

    .title {
        font-size  : 1.75rem;
        font-weight: bold;
    }

    .description {
        width     : 100%;
        max-width : 420px;
        margin    : 0 auto;
        font-size : 1rem;
        text-align: center;
    }

    button {
        position  : absolute;
        top       : 0;
        right     : 0;
        padding   : 0.5rem;
        border    : none !important;
        background: none !important;
        outline   : none !important;
    }

    button svg {
        width : 1rem;
        height: 1rem;
        margin: 0.25rem;
        fill  : #202020;
    }

    button:focus {
        outline   : none !important;
        box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 25%) !important;
    }
}