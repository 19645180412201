.search_products {
    margin-top: 2rem;
}

.search_products__title {
    display       : block;
    margin-bottom : 1.25rem;
    font-size     : 1.25rem;
    font-weight   : bold;
    text-transform: uppercase;
}

@media screen and (min-width: 576px) {}

@media screen and (min-width: 768px) {
    .search_products__title {
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 992px) {}

@media screen and (min-width: 1200px) {
    .search_products__title {
        font-size: 1.75rem;
    }
}