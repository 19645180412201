.statement {
    display        : flex;
    flex-wrap      : wrap;
    justify-content: center;
    align-items    : center;
    width          : 100%;
    margin-bottom  : 1rem;
}

.statement__body {
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
}

.statement__title {
    margin-bottom : 1rem;
    color         : #F5AE30;
    font-size     : 1.5rem;
    font-weight   : bold;
    text-transform: uppercase;
}

.statement__description {
    color      : #868686;
    font-size  : 1rem;
    text-align : center;
    line-height: 180%;
}

@media screen and (min-width: 576px) {}

@media screen and (min-width: 768px) {
    .statement {
        padding: 1rem 2rem !important;
    }

    .statement__title {
        font-size: 1.75rem;
    }

    .statement__description {
        font-size: 1.25rem;
    }
}

@media screen and (min-width: 992px) {
    .statement__wrapper {
        margin-top: 4rem;
    }

    .statement__description {
        font-size : 1.25rem;
        text-align: center;
    }
}

@media screen and (min-width: 1200px) {
    .statement {
        padding: 2rem 4rem !important;
    }

    .statement__title {
        margin-bottom: 2rem;
        font-size    : 1.75rem;
    }

    .statement__description {
        font-size : 1.25rem;
        text-align: center;
    }
}