.form_technical_concern {
    width        : 100%;
    margin-bottom: 2rem;
    padding      : 0;

    form {
        width: 100%;
    }

    .form__header {
        margin-bottom: 3rem;
    }

    .form-floating {
        margin-bottom: 2rem;
    }

    .form-floating>.form-control:focus~label,
    .form-floating>.form-control:not(:placeholder-shown)~label,
    .form-floating>.form-select~label {
        height     : initial;
        padding    : 0.5rem 1rem;
        color      : #868686;
        font-size  : 1.25rem;
        font-weight: bold;
        background : #FFFFFF;
        opacity    : 1;
        transform  : scale(.85) translateY(-1.5rem) translateX(1rem);
    }

    .title {
        display      : block;
        margin-bottom: 1rem;
        color        : #F5AE30;
        font-size    : 1.5rem;
        font-weight  : bold;
    }

    .description {
        color: #868686;
    }

    button {
        padding-top   : 1rem;
        padding-bottom: 1rem;
        font-size     : 0.95rem;
        text-transform: uppercase;
        border        : none !important;
        color         : #202020 !important;
        background    : #F5AE30 !important;
        outline       : none !important;
    }

    button:focus {
        border-color: #F5AE30 !important;
        box-shadow  : 0 0 0 0.25rem rgb(245 174 48 / 25%) !important;
    }
}

@media screen and (min-width: 576px) {}

@media screen and (min-width: 768px) {}

@media screen and (min-width: 992px) {}

@media screen and (min-width: 1200px) {}