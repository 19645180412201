.form_search {
    position       : relative;
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : row;
    justify-content: center;
    align-items    : stretch;
    width          : 100%;
}

.form_search__title {
    display       : block;
    margin-bottom : 1.25rem;
    color         : #F5AE30;
    font-size     : 1.25rem;
    font-weight   : bold;
    text-transform: uppercase;
}

.form_search .form-floating {
    width        : 100%;
    margin-bottom: 1rem;
}

.form_search .form-floating:nth-child(1) {
    margin-left: 0 !important;
}

.form_search button {
    width        : 100%;
    margin-bottom: 1rem;
}

.form_search button:nth-child(4) {
    font-size     : 1rem;
    text-transform: uppercase;
    border        : none !important;
    outline       : none !important;
    background    : #3896B3 !important;
}

.form_search button:nth-child(5) {
    margin-right  : 0 !important;
    color         : #3896B3 !important;
    font-size     : 1rem;
    text-transform: uppercase;
    border-color  : #3896B3;
    outline       : none !important;
    background    : none;
}

@media screen and (min-width: 576px) {}

@media screen and (min-width: 768px) {
    .form_search__title {
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 992px) {
    .form_search {
        justify-content: space-between;
    }

    .form_search .form-floating {
        flex  : 1 0 15%;
        margin: 0 0.5rem;
    }

    .form_search button {
        flex  : 1 0 15%;
        margin: 0 0.5rem;
    }
}

@media screen and (min-width: 1200px) {
    .form_search__title {
        font-size: 1.75rem;
    }
}