.page__header {
    display            : flex;
    justify-content    : center;
    align-items        : center;
    padding            : 1.5rem 3rem;
    background-color   : #202020;
    background-image   : url('../../../assets/images//header.png');
    background-repeat  : no-repeat;
    background-size    : cover;
    background-position: center;
    box-shadow         : rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.page__title {
    color         : #FFFFFF;
    font-size     : 2rem;
    text-transform: uppercase;
    text-align    : center;
}

@media screen and (max-width: 998px) {
    .page__title {
        font-size: 1.8rem;
    }
}

@media screen and (max-width: 768px) {
    .page__title {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 576px) {
    .page__title {
        font-size: 1.25rem;
    }
}