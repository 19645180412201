.map {
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : row;
    justify-content: center;
    align-items    : stretch;
    width          : 100%;
    height         : 100%;
    margin-top     : 1.5rem;
}

.map__object {
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    width          : 100%;
}

@media screen and (min-width: 576px) {}

@media screen and (min-width: 768px) {
    .map__title {
        font-size: 1.75rem;
    }
}

@media screen and (min-width: 992px) {
    .map {
        margin      : 0;
        padding-left: 2rem;
    }
}

@media screen and (min-width: 1200px) {
    .map__title {
        font-size: 2rem;
    }
}