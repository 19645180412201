.product_card {
    position         : relative;
    display          : flex;
    flex-direction   : column;
    margin-bottom    : 2rem;
    background       : #FBFBFB;
    /* box-shadow    : rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}

.product_card__link {
    position  : absolute;
    top       : 0;
    bottom    : 0;
    z-index   : 999;
    width     : 100%;
    min-height: 100%;
}

.product_card__image {
    width              : 100%;
    min-height         : 320px;
    background-repeat  : no-repeat;
    background-size    : 80%;
    background-position: center;
    background-color   : #F5F5F5;
}

.product_card__body {
    height        : 100%;
    display       : flex;
    flex-direction: column;
    padding       : 1rem 0;
}

.product_card__name {
    width         : 100%;
    margin-bottom : 0.25rem;
    font-size     : 1.25rem;
    word-wrap     : wrap;
    text-align    : center;
    text-transform: uppercase;
}

.product_card__price {
    color      : rgba(0, 0, 0, 0.75);
    font-size  : 0.9rem;
    font-weight: 300;
    text-align : center;
    word-wrap  : wrap;
}

@media screen and (min-width: 576px) {
    .product_card__image {
        min-height: 380px;
    }
}

@media screen and (min-width: 768px) {
    .product_card__image {
        min-height: 320px;
    }
}

@media screen and (min-width: 992px) {}

@media screen and (min-width: 1200px) {
    .product_card__image {
        min-height: 340px;
    }
}

.product_card:hover {
    transform: scale(1.025);
}