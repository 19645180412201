@font-face {
    font-family: Ubuntu;
    src        : url(../src/assets/fonts/Ubuntu-Regular.ttf);
}

* {
    font-family: Ubuntu !important;
}


body * {
    transition: all 0.2s ease-out;
}

@media screen and (min-width: 576px) {}

@media screen and (min-width: 768px) {
    .loading_screen svg {
        width : 120px;
        height: 120px;
    }
}

@media screen and (min-width: 992px) {}

@media screen and (min-width: 1200px) {}


/*

Scrollbar Overrides

*/

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #868686;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #3896B3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #3896B3;
}

/*

Form Overrides

*/

.form-control:focus {
    border-color: #F5AE30 !important;
    box-shadow  : 0 0 0 0.25rem rgb(245 174 48 / 25%) !important;
}

.form-select:focus {
    border-color: #F5AE30 !important;
    box-shadow  : 0 0 0 0.25rem rgb(245 174 48 / 25%) !important;
}

/*

Pagination Overrides

*/

.pagination {
    flex-wrap      : wrap;
    flex-direction : row;
    justify-content: center;
    align-items    : stretch;
    width          : 100% !important;
}

.page-item {
    margin: 0 0 0 0.5rem !important;
}

.page-link {
    display        : flex;
    justify-content: center;
    align-items    : center;
    height         : 100%;
    color          : #868686 !important;
    border-radius  : 0.25rem !important;
}

.page-item.active .page-link {
    color           : #FFFFFF !important;
    border-color    : #3896B3 !important;
    background-color: #3896B3 !important;
}

@media screen and (min-width: 576px) {}

@media screen and (min-width: 768px) {}

@media screen and (min-width: 992px) {}

@media screen and (min-width: 1200px) {
    .pagination {
        justify-content: flex-end;
    }
}