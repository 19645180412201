/*
NOTICE:

Styles have been design with mobile first principle

*/

.footer {
    position        : relative;
    color           : #ffffff;
    background-color: #4E4E4E;
    padding-top     : 2rem;
}

.footer__body {
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : column;
    justify-content: center;
    padding        : 2rem 1rem;
}

.footer__column {
    flex          : 1 0 100%;
    display       : flex;
    flex-wrap     : wrap;
    flex-direction: column;
}

.column__title {
    margin-bottom : 1rem;
    font-weight   : bold;
    text-transform: uppercase;
}

.column__description {
    margin-bottom: 1rem;
    color        : rgba(255, 255, 255, 0.6);
    font-weight  : 300;

}

.column__body {
    display       : flex;
    flex-wrap     : wrap;
    flex-direction: column;
    padding       : 0 0 2rem 0;
    font-weight   : 300;
}

.column__link {
    margin-bottom  : 0.5rem;
    color          : rgba(255, 255, 255, 0.6);
    font-weight    : 300;
    text-decoration: none;
}

.column__link:hover {
    color : #F5AE30;
    cursor: pointer;
}

.column__link--address {
    line-height: 150%;
}

.footer__copyright {
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    width          : 100%;
    margin-top     : 2rem;
    padding        : 1rem;
    background     : #868686;
}

.copyright__text {
    display        : flex;
    justify-content: center;
    font-size      : 0.9rem;
    text-align     : center;
}

@media screen and (min-width: 576px) {
    .footer__body {
        flex-direction: row;
    }

    .footer__column {
        flex: 1 0 40%;
    }

    .footer__column:nth-child(odd) {
        margin-right: 1rem;
    }

    .footer__column:nth-child(2n) {
        padding-right: 1rem;
    }
}

@media screen and (min-width: 768px) {
    .footer__body {
        padding: 2rem;
    }

}

@media screen and (min-width: 992px) {
    .footer__column {
        flex: 1 0 20%;
    }

    .footer__column:last-child {
        margin-right : 0;
        padding-right: 0;
    }
}

@media screen and (min-width: 1200px) {
    .footer__body {
        padding: 2rem 4rem;
    }

    .footer__link:nth-child(odd) {
        margin: 0 0.5rem 0 0;
    }
}