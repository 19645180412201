/*
NOTICE:

Styles have been design with mobile first principle

*/

.topbar {
    width     : 100%;
    padding   : 0 1rem !important;
    background: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.topbar__brand {
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : row;
    justify-content: space-between;
    width          : 100%;
}

.logo__wrapper {
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;
}

.topbar__logo {
    width              : 80px;
    height             : 44px;
    margin             : 1rem 0;
    background         : url('../../../assets/images/apti_logo.png');
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : cover;
}

.topbar__text {
    display        : none;
    margin-left    : 1rem;
    color          : #444444;
    font-size      : 0.95rem;
    font-weight    : 400;
    line-height    : 120%;
    text-transform : uppercase;
    text-decoration: none;
    text-align     : justify;
}

.topbar__text:hover {
    color: black;
}

.topbar__text span:first-child {
    letter-spacing: 1px;
    font-size     : 1.2rem;
    font-weight   : bold;
}

.topbar__text span:last-child {
    font-size: 0.9rem;
}

.topbar button {
    max-height: 3rem;
    margin    : auto 0 !important;
    padding   : 0.5rem;
}

.topbar button span {
    width : 1.5rem;
    height: 1.5rem;
}

.topbar .navbar-collapse {
    display       : flex;
    flex-wrap     : wrap;
    flex-direction: row;
    align-items   : center;
}

.topbar .navbar-nav {
    width      : 100%;
    margin     : 0 0 2rem;
    padding-top: 1.25rem;
}

.topbar .nav-item {
    display      : flex;
    flex-wrap    : nowrap;
    align-items  : center;
    margin-bottom: 0.5rem;
    height       : 100%;
}

.topbar .nav-link {
    display       : flex;
    align-items   : center;
    height        : 100%;
    padding       : 0 !important;
    font-size     : 0.95rem;
    text-transform: uppercase;
    white-space   : nowrap;
    border-bottom : 2px solid #FFFFFF;
}

.topbar .nav-link.active {
    color       : #F5AE30 !important;
    font-weight : bold;
    border-color: #F5AE30;
}

@media screen and (min-width: 576px) {
    .topbar__text {
        display        : flex;
        flex-wrap      : nowrap;
        flex-direction : column;
        justify-content: center;
        align-items    : flex-start;
    }
}

@media screen and (min-width: 768px) {
    .topbar {
        padding: 0 2rem !important
    }
}

@media screen and (min-width: 992px) {
    .topbar .navbar-nav {
        display        : flex;
        justify-content: center;
        align-items    : center;
        margin         : 0;
    }

    .topbar .nav-item {
        margin-left: 1.5rem;
    }

    .topbar .navbar-nav {
        padding   : 0;
        border-top: none;
    }

    .topbar .nav-item {
        margin-bottom: 0;
    }
}

@media screen and (min-width: 1200px) {
    .topbar {
        padding: 0 4rem !important;
    }
}

@media screen and (min-width: 1500px) {}