.locations__banner {
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    width          : 100%;
    margin         : 5rem 0 2rem;
}

.locations__title {
    width      : 100%;
    color      : #868686;
    font-size  : 1.25rem;
    font-weight: bold;
    text-align : center;
}

@media screen and (min-width: 576px) {}

@media screen and (min-width: 768px) {
    .locations__title {
        width    : 50%;
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 992px) {}

@media screen and (min-width: 1200px) {
    .locations__title {
        width    : 50%;
        font-size: 2rem;
    }
}