.news_list {
    margin: 2rem 0;
}

.news_list__title {
    display      : block;
    margin-bottom: 1rem;
    font-size    : 1.25rem;
    font-weight  : bold;
}

.news_list__body {
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    margin-bottom  : 1.5rem;
}

.news_list__body>div {
    width        : 100%;
    margin-bottom: 1.5rem;
}

.news_list__placeholder {
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    width          : 100%;
    height         : 420px;
    border-radius  : 0.25rem;
}

@media screen and (min-width: 576px) {}

@media screen and (min-width: 768px) {
    .news_list__body>div {
        flex     : 1 0 45%;
        max-width: 45%;
    }

    .news_list__body>div:nth-child(odd) {
        margin-right: 1rem;
    }

    .news_list__title {
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 992px) {}

@media screen and (min-width: 1200px) {
    .news_list__body>div {
        flex     : 1 0 22%;
        max-width: 22%;
    }

    .news_list__body>div {
        margin: 1rem;
    }

    .news_list__body>div:first-child {
        margin-left: 0;
    }

    .news_list__body>div:nth-child(4n) {
        margin-right: 0;
    }

    .news_list__body>div:last-child {
        margin-right: 0;
    }

    .news_list__title {
        font-size: 2rem;
    }
}