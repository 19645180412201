.accordion-item {
    margin-bottom: 2rem;
    border       : none !important;
    border-radius: 0 !important;
}

.accordion-button {
    color        : #F5AE30 !important;
    font-size    : 1rem;
    font-weight  : bold;
    border-bottom: 1px solid #F5AE30 !important;
    background   : none !important;
}

.accordion-button:not(.collapsed) {
    font-weight: bold;
    background : none !important;
}

.accordion-button:focus {
    outline   : none !important;
    box-shadow: none !important;
}

.accordion-body {
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : column;
    justify-content: flex-start;
}

.locations__store {
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : column;
    justify-content: flex-start;
    align-items    : flex-start;
    margin         : 1rem 0 3rem;
}

.locations_card__store {
    margin-bottom : 1rem;
    color         : rgba(0, 0, 0, 0.75);
    font-size     : 1rem;
    font-weight   : 800;
    text-transform: uppercase;
}

.locations__store svg {
    width       : 20px;
    height      : 20px;
    margin-right: 0.75rem;
    fill        : rgba(0, 0, 0, 0.65);
}

.address__wrapper {
    display        : flex;
    flex-wrap      : nowrap;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    margin-bottom  : 0.5rem;
}

.locations_card__address {
    color         : #4E4E4E !important;
    font-size     : 1rem;
    text-transform: capitalize;
}

.locations_card__business_hours {
    color    : #4E4E4E !important;
    font-size: 1rem;
}

.locations__placeholder {
    padding   : 2rem 0;
    color     : rgba(0, 0, 0, 0.5);
    font-size : 1.1rem;
    text-align: center;
}

@media screen and (min-width: 576px) {}

@media screen and (min-width: 768px) {}

@media screen and (min-width: 992px) {
    .accordion-button {
        font-size: 1.5rem !important;
    }

    .locations_card__store {
        font-size: 1.25rem;
    }

    .locations_card__address {
        font-size: 1rem;
    }

    .locations_card__business_hours {
        font-size: 1rem;
    }
}

@media screen and (min-width: 1200px) {
    .accordion-button {
        font-size: 1.75rem !important;
    }

    .locations_card__store {
        font-size: 1.75rem;
    }

    .locations_card__address {
        font-size: 1.25rem;
    }

    .locations_card__business_hours {
        font-size: 1.25rem;
    }
}