.product {
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : column;
    justify-content: flex-start;
    width          : 100%;
    height         : 100%;
    margin-bottom  : 2rem;
}

.product__header {
    display        : flex;
    flex-direction : column;
    justify-content: flex-start;
    align-items    : stretch;
    width          : 100%;
    margin         : 2rem 0 0 0;
}

.product__image {
    width              : 100%;
    height             : 100%;
    min-height         : 340px;
    margin-bottom      : 2rem;
    border-radius      : 3px;
    background-repeat  : no-repeat;
    background-size    : 80%;
    background-position: center;
}

.product__content {
    display        : flex;
    flex-wrap      : nowrap;
    flex-direction : column;
    justify-content: flex-start;
    width          : 100%;
    height         : 100%;
}

.product__name {
    margin-bottom : 1rem;
    color         : #F5AE30;
    font-size     : 1.25rem;
    font-weight   : bold;
    text-transform: uppercase;
    line-height   : 100%;
}

.product__price {
    margin-bottom: 1rem;
    font-size    : 1rem;
    color        : rgb(0, 0, 0, 0.65);
}

.product__details {
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;
}

.product__category {
    padding      : 0.25rem 0.5rem;
    color        : #FFFFFF;
    font-size    : 0.9rem;
    border-radius: 3px;
    background   : #3896B3;
    cursor       : pointer;
}

.product__body {
    width     : 100%;
    margin-top: 2rem;
}

.product__description {
    margin-top: 2rem;
}

.product__title {
    margin-bottom: 1rem;
    color        : rgb(0, 0, 0, 0.65);
    font-size    : 1.25rem;
    font-weight  : bold;
}

.product__description p {
    height        : 100%;
    color         : rgb(0, 0, 0, 0.65);
    font-size     : 1rem;
    text-transform: capitalize;
}

@media screen and (min-width: 576px) {}

@media screen and (min-width: 768px) {
    .product__image {
        min-height: 520px;
    }
}

@media screen and (min-width: 992px) {
    .product__header {
        display        : flex;
        flex-direction : row;
        justify-content: flex-start;
        align-items    : flex-start;
        width          : 100%;
        margin         : 2rem 0 1rem 0;
    }

    .product__content {
        padding: 0 2rem;
    }

    .product__image {
        max-width: 520px;
        margin   : 0;
    }

    .product__name {
        margin-bottom: 1rem;
        font-size    : 1.75rem;
    }

    .product__price {
        font-size: 1.25rem;
    }
}

@media screen and (min-width: 1200px) {
    .product__name {
        margin-bottom: 1rem;
        font-size    : 2rem;
    }

    .product__price {
        font-size: 1.25rem;
    }
}