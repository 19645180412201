.article {
    min-height: 720px;
}

.article__information {
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : row;
    justify-content: space-between;
    align-items    : flex-start;
    margin-bottom  : 1.5rem;
    font-size      : 0.8rem;
}

.article__group {
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : column;
    justify-content: flex-start;
    font-size      : 1rem;
    color          : rgba(0, 0, 0, 0.6);
}

.article__group>span {
    margin-bottom: 0.5rem;
}

.article__category {
    padding         : 0.5rem;
    color           : #FFFFFF;
    font-weight     : bold;
    text-transform  : uppercase;
    white-space     : nowrap;
    border-radius   : 0.25rem;
    background-color: #F5AE30;
}

.article__body {
    font-size     : 1.25rem;
    color         : rgba(0, 0, 0, 0.65);
    letter-spacing: 0.35px;
    line-height   : 180%;
}

@media screen and (min-width: 576px) {}

@media screen and (min-width: 768px) {
    .article__location {
        font-size: 1.25rem;
    }
}

@media screen and (min-width: 992px) {}

@media screen and (min-width: 1200px) {}