.loading_screen {
    position        : fixed;
    top             : 0;
    left            : 0;
    bottom          : 0;
    z-index         : 999999;
    display         : flex;
    flex-wrap       : nowrap;
    flex-direction  : column;
    justify-content : center;
    align-items     : center;
    width           : 100%;
    min-height      : 100%;
    background-color: #202020;
}

.loading_screen svg {
    width : 60px;
    height: 60px;
}