.related_articles {
    margin-top: 2rem;
}

.related_articles__title {
    display       : block;
    margin-bottom : 1rem;
    color         : #F5AE30;
    font-size     : 1.25rem;
    font-weight   : bold;
    text-transform: uppercase;
}

.related_articles__body {
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
}

.related_articles__body>div {
    width : 100%;
    margin: 0 0 2rem 0 !important;
}

@media screen and (min-width: 576px) {}

@media screen and (min-width: 768px) {
    .related_articles__title {
        font-size: 1.75rem;
    }

    .related_articles .news_card__image {
        min-height: 420px;
    }
}

@media screen and (min-width: 992px) {
    .related_articles {
        margin: 0 0 0 2rem;
    }

    .related_articles__body>div {
        margin-bottom: 2rem !important;
    }

    .related_articles .news_card__image {
        min-height: 380px !important;
    }
}

@media screen and (min-width: 1200px) {}