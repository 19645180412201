.product_type_card {
    position      : relative;
    display       : flex;
    flex-wrap     : wrap;
    flex-direction: column;
    width         : 100%;
    background    : #FBFBFB;
}

.product_type_card__link {
    position  : absolute;
    top       : 0;
    bottom    : 0;
    z-index   : 999;
    width     : 100%;
    min-height: 100%;
}

.product_type_card svg {
    width : 12rem;
    height: 12rem;
    fill  : #AFAFAF;
}

.product_type_card__image {
    display            : flex;
    justify-content    : center;
    align-items        : center;
    width              : 100%;
    min-height         : 320px;
    background-repeat  : no-repeat;
    background-size    : 80%;
    background-position: center;
    background-color   : #F5F5F5;
}

.product_type_card__body {
    padding   : 1.5rem 0;
    text-align: center;
}

.product_type_card__title {
    width         : 100%;
    font-size     : 0.95rem;
    word-wrap     : wrap;
    text-transform: uppercase;
    text-align    : center;
}

.product_type_card:hover {
    transform: scale(1.05);
}