.product_types_list {
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    margin         : 2rem 0;
}

.product_types_list>div {
    margin-bottom: 1rem;
}

@media screen and (min-width: 576px) {}

@media screen and (min-width: 768px) {
    .product_types_list>div {
        flex     : 1 0 45%;
        margin   : 1rem;
        max-width: 45%;
    }
}

@media screen and (min-width: 992px) {
    .product_types_list>div {
        flex     : 1 0 30%;
        margin   : 1rem;
        max-width: 30%;
    }
}

@media screen and (min-width: 1200px) {
    .product_types_list>div {
        flex     : 1 0 22%;
        margin   : 1rem;
        max-width: 22%;
    }
}