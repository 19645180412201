.about__landing {
    width : 100% !important;
    margin: 2rem 0 !important;
}

.about__wrapper {
    width  : 100%;
    padding: 2rem 0;
}

.about__video {
    display        : flex;
    justify-content: center;
    align-items    : stretch;
    width          : 100%;
    min-height     : 420px;
    background     : #ECEFF1;
}

.about__video iframe {
    width     : 100%;
    min-height: 420px;
}

.about__body {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : flex-start;
    width          : 100%;
    min-height     : 100%;
    padding        : 1rem 0;
}

.about__title {
    width         : 100%;
    margin-bottom : 1rem;
    color         : #F5AE30;
    font-size     : 1.5rem;
    font-weight   : bold;
    text-transform: uppercase;
    text-align    : center;
}

.about__description {
    color      : #868686;
    font-size  : 1rem;
    text-align : center;
    line-height: 180%;
}

@media screen and (min-width: 576px) {}

@media screen and (min-width: 768px) {
    .about__wrapper {
        padding: 0 1rem;
    }

    .about__image {
        min-height: 420px;
    }

    .about__body {
        padding: 2rem;
    }

    .about__title {
        font-size: 1.75rem;
    }

    .about__description {
        font-size: 1.25rem;
    }
}

@media screen and (min-width: 992px) {
    .about__body {
        padding: 0 1rem;
    }

    .about__title {
        text-align: left;
    }

    .about__description {
        text-align: left;
    }
}

@media screen and (min-width: 1200px) {
    .about__wrapper {
        padding: 0 2rem;
    }

    .about__body {
        padding: 0 2rem;
    }

    .about__title {
        font-size: 2rem;
    }

    .about__description {
        font-size: 1.25rem;
    }
}