.contact {
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : row;
    justify-content: space-between;
    align-items    : flex-start;
    width          : 100%;
    margin-top     : 2rem;
}

@media screen and (min-width: 576px) {}

@media screen and (min-width: 768px) {}

@media screen and (min-width: 992px) {}

@media screen and (min-width: 1200px) {}

@media screen and (min-width: 1500px) {}