.form_location_search {
    width        : 100%;
    margin-bottom: 5rem;
}

.form_location_search>div {
    display        : flex;
    flex-wrap      : nowrap;
    justify-content: center;
    align-items    : stretch;
}

.form_location_search div:last-child {
    width   : 70%;
    margin  : 0 auto;
    position: relative;
}

.form_location_search .form-control {

    border-radius: 2rem !important;
}

.form_location_search button {
    position     : absolute;
    top          : 1px;
    right        : 0;
    padding      : 0.5rem 1.5rem;
    border       : none !important;
    border-radius: 2rem;
    background   : #F5AE30 !important;
    outline      : none !important;
}

.form_location_search button svg {
    width : 24px;
    height: 24px;
    fill  : #202020;
}

.form_location_search button:focus {
    border    : none !important;
    outline   : none !important;
    box-shadow: 0 0 0 0.25rem rgb(245 174 48 / 25%) !important;
}