.article__landing {
    margin: 0 0 1rem 0;
}

.article__image {
    position           : relative;
    z-index            : -1;
    display            : flex;
    flex-wrap          : wrap;
    flex-direction     : column;
    justify-content    : center;
    align-items        : center;
    width              : 100%;
    min-height         : 400px;
    background-repeat  : no-repeat;
    background-size    : cover;
    background-position: center;
}

.article__header {
    position       : absolute;
    top            : 0;
    left           : 0;
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    width          : 100%;
    min-height     : 100%;
    padding        : 0 1rem;
    color          : #FFFFFF;
    background     : rgba(0, 0, 0, 0.8);
}

.article__title {
    font-size     : 1.75rem;
    font-weight   : bold;
    text-transform: capitalize;
}


.article__details {
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    margin-top     : 5rem;
    font-size      : 1rem;

}

.article__details>span {
    margin        : 0.25rem 3rem;
    text-transform: uppercase;
}

.article__details>span:first-child {
    color: rgba(255, 255, 255, 0.75);
}

.article__details>span:last-child {
    font-weight: bold;

}

@media screen and (min-width: 576px) {
    .article__landing {
        margin-bottom: 2rem;
    }

    .article__image {
        min-height: 500px;
    }

    .article__title {
        font-size: 2rem;
    }
}

@media screen and (min-width: 768px) {
    .article__header {
        padding: 0 2rem;
    }

    .article__title {
        font-size: 2.25rem;
    }
}

@media screen and (min-width: 992px) {
    .article__title {
        font-size: 2.5rem;
    }
}


@media screen and (min-width: 1200px) {
    .article__header {
        padding: 0 4rem;
    }

    .article__title {
        font-size: 3rem;
    }
}