.contact_information {
    width        : 100%;
    height       : 100%;
    margin-bottom: 2rem;
    padding      : 2rem;
    border-radius: 0.25rem;
    background   : #F5AE30;
}

.contact__header {
    width         : 100%;
    display       : block;
    margin-bottom : 2rem;
    color         : #F5AE30;
    font-size     : 1.5rem;
    font-weight   : bold;
    text-transform: uppercase;
    text-align    : center;
}

.contact_information__title {
    display       : block;
    margin-bottom : 1rem;
    color         : #FFFFFF;
    font-size     : 1rem;
    font-weight   : bold;
    text-transform: uppercase;
}

.contact_information__details {
    margin-bottom  : 2.5rem;
    padding        : 0;
    color          : #FFFFFF;
    list-style-type: none;
}

.contact_information__detail {
    display        : flex;
    flex-wrap      : nowrap;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : flex-start;
    margin-bottom  : 1.5rem;
}

.contact_information__detail svg {
    min-width : 20px;
    max-width : 20px;
    min-height: 20px;
    max-height: 20px;
    margin    : 0.25rem 1rem 0 0;
    fill      : #FFFFFF;
}

.contact_information__detail span {
    max-width    : 420px;
    text-overflow: wrap;
    font-size    : 0.9rem;
}

@media screen and (min-width: 576px) {}

@media screen and (min-width: 768px) {
    .contact__header {
        font-size: 1.75rem;
    }

    .contact_information__title {
        font-size: 1.5rem;
    }

    .contact_information__detail span {
        font-size: 1rem;
    }
}

@media screen and (min-width: 992px) {}

@media screen and (min-width: 1200px) {
    .contact__header {
        font-size: 2rem;
    }
}