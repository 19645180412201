/*
NOTICE:

Styles have been design with mobile first principle

*/

.featured_product {
    margin-bottom: 2rem;
}

.featured_product__wrapper {
    display            : flex;
    justify-content    : center;
    align-items        : center;
    width              : 100%;
    min-height         : 85vh;
    background         : url('../../../assets/images/home_bg.png');
    background-repeat  : no-repeat;
    background-position: left top;
    background-size    : cover;
}

.featured_product__image {
    width              : 100%;
    height             : 100%;
    min-height         : 520px;
    background-repeat  : no-repeat;
    background-size    : 80%;
    background-position: center;
}

.featured_product__body {
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : column;
    justify-content: center;
    align-items    : flex-start;
    width          : 100%;
    height         : 100%;
    padding        : 2rem;
}

.featured_product__slogan {
    margin-bottom: 1.25rem;
    color        : #F5AE30;
    font-size    : 1.75rem;
    font-weight  : bold;
}

.featured_product__name {
    margin-bottom: 1.25rem;
    color        : #4E4E4E;
    font-size    : 1.5rem;
}

.featured_product__description {
    color      : #868686;
    font-size  : 1.25rem;
    line-height: 180%;
}

.featured_product__button {
    margin-top     : 0.5rem;
    padding        : 1rem 1.5rem;
    color          : #FFFFFF !important;
    text-decoration: none;
    background     : #F5AE30 !important;
    border-radius  : 0.25rem;
}

.carousel-indicators button {
    width        : 75px !important;
    height       : 5px !important;
    border       : 2px solid #F5AE30 !important;
    border-radius: 1rem;
}

.carousel-indicators button.active {
    background: #F5AE30 !important;
}

@media screen and (min-width: 576px) {
    .featured_product__slogan {
        font-size: 2.25rem;
    }

    .featured_product__name {
        font-size: 1.75rem;
    }

    .featured_product__description {
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 768px) {
    .featured_product__body {
        padding: 2rem;
    }

    .featured_product__slogan {
        font-size: 2.5rem;

    }

    .featured_product__name {
        font-size: 2rem;
    }

    .featured_product__description {
        font-size: 1.75rem;
    }
}

@media screen and (min-width: 992px) {
    .featured_product__wrapper {
        height                : 100%;
        /* min-height         : 620px; */
        padding               : 4rem;
        background-position   : left top;
        background-size       : cover;
    }

    .featured_product__slogan {
        font-size: 2.5rem;
    }

    .featured_product__name {
        font-size: 2rem;
    }

    .featured_product__description {
        font-size: 1.25rem;
    }
}

@media screen and (min-width: 1200px) {
    .featured_product__body {
        padding: 4rem 4rem 8rem;
    }
}